import React from 'react';
import Article, { SubTitle } from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import Code from 'components/help/codeSnippet';
import excluding1 from 'img/help/recording-rules/excluding1.png';
import excluding2 from 'img/help/recording-rules/excluding2.png';
import excluding3 from 'img/help/recording-rules/excluding3.png';
import excluding4 from 'img/help/recording-rules/excluding4.png';
import excluding5 from 'img/help/recording-rules/excluding5.png';
import excluding6 from 'img/help/recording-rules/excluding6.png';
import excluding7 from 'img/help/recording-rules/excluding7.png';
import excluding8 from 'img/help/recording-rules/excluding8.png';
import excluding9 from 'img/help/recording-rules/excluding9.png';

const Content = () => {
  return (
    <div>
      <p>To exclude recordings for a specific variable:</p>
      <ol>
        <li>
          <p>
            <strong>Go to Settings</strong> &#8594;<strong> Websites</strong>. Find the website you
            would like to edit and click on “Edit” on the right side:
          </p>
          <Image
            src={excluding1}
            alt="Go to Settings and click Edit on website"
            title="Go to Settings"
          />
        </li>
        <li>
          <p>
            Once you enter there, you’ll get a screen like below, where you click on{' '}
            <strong>“Recording rules”</strong>
          </p>{' '}
          <Image
            src={excluding2}
            lazy
            alt="In next window, click on Recording rules"
            title="Click Recording rules"
          />
        </li>
        <li>
          <p>The next window will look like the following:</p>
          <Image
            src={excluding3}
            lazy
            alt="Page contains “Don’t record URLs, Block IP Address, Block location and Block User Agents"
            title="Page with recording rules"
          />
        </li>
        <li>
          You can exclude recordings based on their URL, IP address, location, and user agents
        </li>
        <li>
          <p>
            While IP, location and user agents are self-explanatory in terms of blocking, with the
            URL it is a bit more complex. Click on <strong>Don’t record URLs</strong>, the following
            will appear
          </p>
          <Image
            src={excluding4}
            setMarginBottom
            lazy
            alt="Click on Don’t record URLs to write web address you wish to exclude"
            title="Click on Don’t record"
          />
        </li>
        <li>
          <p>
            You can exclude an URL that contains subpages, f.e{' '}
            <Code inline>example.com/fruits</Code> , but at the same time RECORD a subpage within
            the URL you have excluded
          </p>
          <Image
            src={excluding5}
            lazy
            alt=" Indicate URs that can be recorded despite set rules"
            title="Except URLs"
          />
        </li>
        <li>
          <p>
            You can add more variables if you’d like. Additionally, click Save changes on the bottom
            of the page save your new settings.
          </p>
          <Image
            src={excluding6}
            lazy
            alt="Click Save changes to save new settings"
            title=" Click Save changes"
          />
        </li>
      </ol>
      <SubTitle> How to properly exclude elements from recordings</SubTitle>
      <p>
        Excluding URLs from recording sessions was explained in this article, but sometimes it is
        difficult to know just what kind of URLs can be excluded when typing them in the{' '}
        <strong>Don’t record URLs</strong> section in <strong>Recording rules</strong>. Below is a
        table which will clarify the rules of which URLs are excluded and which ones aren’t when
        inputting them.
      </p>
      <Image src={excluding7} lazy alt="Don’t record URLs" title="Don’t record URLs" />
      <table>
        <thead>
          <tr>
            <td>
              <strong>Example URL</strong>
            </td>
            <td>
              <strong>Why?</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              example.com{' '}
              <span role="img" aria-label="emoji">
                ❌
              </span>
            </td>
            <td>The URL address is the exact same one as inputted</td>
          </tr>
          <tr>
            <td>
              example.com?utm_source=google{' '}
              <span role="img" aria-label="emoji">
                ✅{' '}
              </span>
            </td>
            <td>URL is not the same</td>
          </tr>
          <tr>
            <td>
              example.com/page1{' '}
              <span role="img" aria-label="emoji">
                ✅{' '}
              </span>
            </td>
            <td>URL is not the same</td>
          </tr>
        </tbody>
      </table>
      <p>
        Additionally, you can stop session recording of a URL link that contains a subpage,
        resulting in websites containing that URL not being recorded as well:
      </p>
      <Image
        src={excluding8}
        lazy
        alt="Don’t record URLs that contain certain web address"
        title="Don’t record URLs containing"
      />
      <table>
        <thead>
          <tr>
            <td>
              <strong>Example URL</strong>
            </td>
            <td>
              <strong>Why?</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              example.com/page1{' '}
              <span role="img" aria-label="emoji">
                ❌
              </span>
            </td>
            <td>Contains ‘example.com/page’</td>
          </tr>
          <tr>
            <td>
              example.com/page2{' '}
              <span role="img" aria-label="emoji">
                ❌{' '}
              </span>
            </td>
            <td>Contains ‘example.com/page’</td>
          </tr>
          <tr>
            <td>
              example.com/index{' '}
              <span role="img" aria-label="emoji">
                ✅{' '}
              </span>
            </td>
            <td>Does not contain ‘example.com/page’</td>
          </tr>
          <tr>
            <td>
              example.com{' '}
              <span role="img" aria-label="emoji">
                ✅{' '}
              </span>
            </td>
            <td>Is not + does not contain ‘example.com/page’</td>
          </tr>
        </tbody>
      </table>
      <p>
        As mentioned higher in the article, you can record URLs while bypassing the{' '}
        <strong>Don’t record URL</strong> by clicking <strong>Except URLs.</strong>
      </p>
      <Image
        src={excluding9}
        lazy
        alt=" Record URLs while bypassing Don’t record URL rules"
        title="Exclude web address from Don’t record URL"
      />
      <table>
        <thead>
          <tr>
            <td>
              <strong>Example URL</strong>
            </td>
            <td>
              <strong>Why?</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              example.com/page2{' '}
              <span role="img" aria-label="emoji">
                ❌
              </span>
            </td>
            <td>Contains URL</td>
          </tr>
          <tr>
            <td>
              example.com/page1{' '}
              <span role="img" aria-label="emoji">
                ✅{' '}
              </span>
            </td>
            <td>Doesn’t contain the URL</td>
          </tr>
          <tr>
            <td style={{ wordBreak: 'break-all' }}>
              example.com/page2/smartphones{' '}
              <span role="img" aria-label="emoji">
                ❌{' '}
              </span>
            </td>
            <td>Contains URL</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-install-livesession-script',
    title: 'How to install LiveSession script?',
    description: 'Check how can you install LiveSession on any website.',
  },
];

export const frontmatter = {
  metaTitle: 'How to exclude recording for a specific IP, country, URL or user agent?',
  metaDescription: 'Take a look how to exclude recording for a specific variable',
  canonical: '/help/how-to-exclude-recording/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Product" title={frontmatter.metaTitle} {...frontmatter} />
);
